
.svg-icon[data-v-6af0732b] {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.svg-external-icon[data-v-6af0732b] {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
