
.modal-mask[data-v-dd9431da] {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper[data-v-dd9431da] {
  z-index: 999;
  display: table-cell;
  vertical-align: middle;
}
.modal-container[data-v-dd9431da] {
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header[data-v-dd9431da] {
  text-align: center;
  display: block;
}
.modal-header h3[data-v-dd9431da] {
  margin-top: 0;
  margin: auto;
}
.modal-body[data-v-dd9431da] {
  margin: 20px 0;
}
.modal-footer[data-v-dd9431da] {
  display: block;
}
.modal-footer div[data-v-dd9431da] {
  width: 100%;
  margin: auto;
}
.modal-footer button[data-v-dd9431da] {
  margin: auto;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter[data-v-dd9431da] {
  opacity: 0;
}
.modal-leave-active[data-v-dd9431da] {
  opacity: 0;
}
.modal-enter .modal-container[data-v-dd9431da],
.modal-leave-active .modal-container[data-v-dd9431da] {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
