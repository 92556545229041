
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* #page-content {
  position: absolute;
  width: 100%;
  top: 0;
  min-height: 900px;
}
#page-content .row {
  margin: 0 !important;
} */
.padding15 {
  padding: 15px;
}
.property-img {
  width: 25vw;
  height: 25vw;
  object-fit: cover;
}
.search-part input {
  border-color: transparent;
}
.tab-pane.nav-menu {
  overflow: auto;
  /* height: 700px!important; */
  border: 2px solid #efeff3 !important;
}
a:hover {
  color: #7b4fff !important;
}
.nav-tabs .nav-link:hover {
  border: none;
}
.card {
  border: unset !important;
}
.card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.nav-tabs {
  padding: 0 10px;
}
.col-auto {
  margin-left: 20px;
  padding: 0 !important;
  width: 250px !important;
  height: 700px !important;
}
.nav-tabs {
  border-bottom: unset !important;
}
/* div.tabs{
  background-color: #f8f9fa !important;
} */
.nav-link {
  display: flex !important;
  justify-content: start;
  align-content: center;
  color: #74798c;
  outline: none;
}
.nav-tabs .nav-link {
  border-radius: 0.25rem !important;
}
.nav-tabs .nav-link.active {
  color: #7b4fff !important;
  background-color: #e0d7fd !important;
}
.hidden {
  display: none !important;
}
.mobile {
  display: none !important;
}
legend {
  text-align: center;
  padding-left: 20px !important;
}
.white {
  color: white;
}
.big-primary {
  font-weight: bold !important;
  color: #8765f3 !important;
}
.big-green {
  font-weight: bold !important;
  color: green !important;
}
.big-orange {
  font-weight: bold !important;
  color: orange !important;
}
.big-red {
  font-weight: bold !important;
  color: red !important;
}
.yellow-title {
  background-color: lightyellow !important;
  border-radius: 10px;
}
.red-title {
  background-color: lightpink !important;
  border-radius: 10px;
}
.green-title {
  background-color: lightgreen !important;
  border-radius: 10px;
}
.blue-title {
  background-color: lightskyblue !important;
  border-radius: 10px;
}
.btn.edit.right {
  padding: 0.375rem 0.375rem;
}
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.collapsed:hover {
  color: #fff !important;
  background-color: #8765f3 !important;
  border-color: #8765f3 !important;
}
.btn-primary.collapsed {
  color: #8765f3 !important;
  background-color: #fff !important;
  border: 2px solid #8765f3 !important;
}
.btn-primary.collapsed[aria-expanded='false']:hover {
  cursor: zoom-in !important;
}
.btn-primary[aria-expanded='true']:hover {
  cursor: zoom-out !important;
}
.btn-back:hover {
  cursor: w-resize !important;
}
.btn-outline-primary, .btn-outline-primary-mx {
  color: #8765f3 !important;
  border: 2px solid #8765f3 !important;
  background-color: white !important;
  border-radius: 25px !important;
}
.btn-outline-primary:hover, .btn-outline-primary-mx:hover, .btn-outline-primary-mx.active  {
  border: 2px solid #8765f3 !important;
  background-color: #8765f3 !important;
  color: white !important;
  cursor: pointer;
}
.btn-outline-primary-mx:not(:last-child) {
  margin-right: 7px;
}
.btn-outline-primary-mx.active {
  border-color: #6044bb !important;
}
.btn-edit {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 2px solid #8765f3;
  background-color: white;
}
.btn-edit:hover {
  background-color: #8765f3;
}
.outline-success {
  color: #28a745 !important;
  border: 2px solid #28a745 !important;
  border-radius: 0.25rem !important;
}
.outline-primary,
.btn-block {
  font-weight: bold;
  color: #8765f3 !important;
  background-color: white !important;
  border: 2px solid #8765f3 !important;
}
.variant-primary {
  font-weight: bold;
  color: white !important;
  background-color: #8765f3 !important;
}
.outline-secondary {
  color: #6c757d !important;
  border: 2px solid #6c757d !important;
  border-radius: 0.25rem !important;
}
div.border {
  margin: 2%;
  border: 1px dashed #ddd;
  box-shadow: 0 0 0 3px #fff, 0 0 0 5px #ddd, 0 0 0 10px #fff, 0 0 2px 10px #eee;
  background-color: white;
}
.range-confirm {
  /* width: 40px; */
  float: right;
}
a.link,
.input-group-text {
  color: #8765f3;
  border: 2px solid #8765f3 !important;
  text-decoration: none;
}
a.link {
  padding: 5px 5px;
  text-align: center;
  border-radius: 0.25rem;
}
a.link:hover,
.clickable_link {
  cursor: pointer;
}
.nav-tabs .nav-link:not(.active):hover {
  border-radius: 0.25rem !important;
}
.input-group-text {
  background-color: white !important;
  border: 1px solid #8765f3 !important;
  display: inline-block !important;
}
input {
  border-color: transparent;
  font-size: 1rem !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #8765f3 !important;
  background-color: #8765f3 !important;
}
.vue-js-switch .v-switch-label.v-right {
  color: #8765f3 !important;
}
.article {
  text-align: left;
  padding: 20px;
}
h5 {
  padding-top: 20px;
}
.top-banner-logo {
  float: left;
  height: 50px;
  width: auto;
}
.page-content {
  margin-top: 60px !important;
}
/* .page {
  min-height: 600px !important;
} */
.content {
  margin-left: 15px;
}
.row {
  margin: 0 !important;
}
.col {
  padding: 0 !important;
}
.page div h2,
.page.content h2 {
  padding-top: 20px;
}
form {
  display: block;
}
#qrcode-div {
  padding-top: 50px;
  width: 50%;
  margin: auto;
}
#qrcode {
  padding: 20px;
}
#copyright {
  padding-top: 10px;
  padding-bottom: 10px;
}
.form-row {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.left {
  float: left;
  left: 20px;
}
.right {
  float: right;
  right: 20px;
}
.right.edit {
  width: 40px;
  margin-right: 0;
  right: 0;
}
.right-text {
  text-align: right;
}
.dropdown-toggle {
  width: 40px;
}
.dropdown-menu {
  border: 2px solid #8765f3 !important;
}
.center {
  margin: 0 auto;
}
.hero-image {
  background-blend-mode: screen;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
center.middle {
  position: relative;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.width80 {
  width: 80% !important;
  margin-left: 0 !important;
  display: inline-block !important;
}
.weather_banner {
  height: 120px;
  width: 100%;
  background-size: cover;
}
.btn-color {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #8765f3),
    color-stop(1, #7b4fff)
  );
  background: -moz-linear-gradient(center top, #8765f3 5%, #7b4fff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8765f3', endColorstr='#7b4fff');
  background-color: #8765f3 !important;
  border-radius: 37px !important;
  border: 1px solid #8765f3 !important;
  color: #ffffff !important;
  /* height:45px;
  width:144px;
  text-align:center; */
  text-shadow: 0px 1px 0px #6339de;
  /* font-size: 1.2rem;
  font-weight: bolder; */
}
.btn-color:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #7b4fff),
    color-stop(1, #8765f3)
  );
  background: -moz-linear-gradient(center top, #7b4fff 5%, #8765f3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7b4fff', endColorstr='#8765f3');
  background-color: #7b4fff !important;
}
/* .idea {
  max-width: 100%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}
.idea:hover {
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
} */
.banner {
  height: 300px;
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner .utility-demo p {
  color: #2c3e50 !important;
}
.banner h1 {
  font-size: 3.7rem;
}
.col-auto {
  height: 93.5vh !important;
  /* border:1px solid red; */
}
/* Desktop/Laptop display */
@media only screen and (min-width: 600px) {
.left {
    margin-left: 20px !important;
}
.right {
    margin-right: 20px !important;
}
  /* .page {
    font-size: larger;
  } */
center.middle button,
  footer div {
    grid-template-columns: auto auto auto;
}
}

/* Mobile Device display */
@media only screen and (max-width: 600px) {
.mobile {
    display: block !important;
}
.top-banner-logo {
    float: none;
}
legend {
    text-align: left;
}
#qrcode {
    display: none;
}
td a.link,
  h2 a.link {
    border: none !important;
    text-decoration: underline;
    border-radius: 0px;
}
}
.form-control:focus {
  border-color: #8765f3 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1),
    0 0 8px rgba(135, 101, 243, 0.6) !important;
}
